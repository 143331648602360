<template>
  <div>
    <v-dialog max-width="887px" class="cdz" v-model="uploadBulkUsers" center>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title class="primary mb-8"
            >Upload Schools in Bulk</v-card-title
          >

          <div class="d-flex justify-center mb-6 bg-surface-variant">
            <div class="box2" @dragover.prevent>
              <div
                class="w-100 d-flex flex-row justify-center"
                style="margin-top: 80px"
              >
                <img src="../../../public/svgs/xlsx_icon 1.svg" />
              </div>

              <div class="w-100 d-flex flex-row justify-center mt-4">
                <div>
                  <div class="font-weight-light text-center">
                    Drag and drop a file or
                    <div class="uplaodbtn2">
                      browse file
                      <input
                        type="file"
                        @change="handleFileUpload"
                        name="csv"
                      />
                    </div>
                    <p class="grey--text">(Supported only .csv file)</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div v-if="image">
                    <div class="imgback">
                      <img :src="image" alt="" width="100" height="auto" class="img" />
                      <div class="pencil">
                        <v-icon class="editpen">mdi-pencil-circle</v-icon>
                        <input class="editlogo" type="file" name="csv" />
                      </div>
                    </div>
                  </div> -->
          </div>

          <v-card-actions class="px-9 pb-6">
            <v-btn text @click="downloadCSV" class="mx-2 downloadColor">
              <img class="mr-2" src="../../../public/svgs/fi_share.svg" />
              <span class="downloadColor"> Download Template Excel </span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              width="102px"
              height="48px"
              outlined
              class="accent--text pa-4"
              @click="
                () => {
                  uploadBulkUsers = false; //
                  formbtnBool = false;
                }
              "
              >Cancel</v-btn
            >
            <!-- @click="uploadUsers" -->
            <v-btn
              depressed
              rounded
              width="102px"
              height="48px"
              class="accent pa-4"
              >Create</v-btn
            >
          </v-card-actions>
          <!---------------------- subject upload section ends here --------------------------->
        </v-card>
      </v-form>
      <!---------------------- subject upload section starts here --------------------------->
    </v-dialog>
    <v-dialog
      max-width="600px"
      max-height="80vh"
      v-model="uploadingUsersDialog"
      center
    >
      <v-card class="pa-4">
        <v-row>
          <v-col cols="6" md="6">
            <v-card-title class="pa-0 ma-0">
              Uploading... {{ bulkSchoolUploadResponse.length }} /
              {{ bulkSchoolsJsonData.length }}
            </v-card-title>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn
              class="float-right"
              icon
              @click="uploadingUsersDialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-data-table
          :headers="headersresponce"
          :items="bulkSchoolUploadResponse"
          :items-per-page="-1"
        >
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex flex-row ml-2">
              {{ item.status }}

              <v-icon
                v-if="item.status.includes('Uploaded Successfully!')"
                color="green"
                >mdi-check-all</v-icon
              >
              <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <img
                width="36px"
                height="36"
                @click="updateData(item)"
                class="cursor"
                src="../../assets/edit.svg"
              />
              <img
                width="36px"
                height="36"
                class="cursor"
                src="../../assets/userdelete.svg"
              />
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-card
      height="64px"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="pl-0 ml-0">
        <p
          class="breadcrumb-text-unselected underline-on-hover cursor"
          @click="$router.push('/masters')"
        >
          Masters
        </p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
          >mdi-chevron-right</v-icon
        >
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Schools
              <v-icon
                class="breadcrumb-arrow cursor"
                style="margin-top: 5px !important"
                >mdi-chevron-down</v-icon
              >
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>

        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Schools</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
        style="position: fixed; right: 0"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <v-hover>
            <img
              class="cursor"
              @click="notificationDialog = true"
              width="24px"
              src="../../assets/bell 1.svg"
            />
          </v-hover>
        </v-badge>

        <div>
          <img
            src="../../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="px-8 pad">
      <v-row>
        <v-col>
          <v-row v-if="dialog" class="px-0 pt-4">
            <v-card class="w-100 ml-4">
              <v-row class="background">
                <v-card-title class="pl-4 mt-3 mb-1"
                  >{{ formbtn() }} School</v-card-title
                >
                <v-spacer></v-spacer>
                <v-card-actions class="mt-3 mb-2">
                  <small class="pa-4 mr-1 mt-2"
                    >*All fields are mandatory</small
                  >
                  <v-btn
                    width="102px"
                    height="40px"
                    rounded
                    text
                    class="accent--text pa-4 mr-1 mt-2"
                    @click="dialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    rounded
                    depressed
                    width="102px"
                    height="40px"
                    @click="saveInputs"
                    class="accent pa-4 mt-2 mr-1"
                    :loading="loading"
                    >{{ formbtn() }}</v-btn
                  >
                </v-card-actions>
              </v-row>

              <v-form ref="form" lazy-validation>
                <v-card id="myScroll" style="height: calc(100vh - 176px)">
                  <v-card-text>
                    <v-row class="my-4 mx-1">
                      <h3>DETAILS</h3>
                    </v-row>
                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          class="rounded-xl"
                          v-model="schoolName"
                          label="Enter School Name*"
                          :rules="[(v) => !!v || 'School name is required']"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          class="rounded-xl"
                          v-model="schoolCode"
                          label="School Code*"
                          :rules="[(v) => !!v || 'School code is required']"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-text-field
                      outlined
                      class="py-0 my-0 rounded-xl"
                      v-model="address"
                      label="Address*"
                      :rules="[(v) => !!v || 'School address is required']"
                      required
                    >
                    </v-text-field>
                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-select
                          label="Select Country*"
                          :rules="[(v) => !!v || 'Country is required']"
                          required
                          v-model="countryName"
                          :items="countries"
                          item-value="name"
                          item-text="name"
                          outlined
                          class="rounded-xl"
                        >
                        </v-select>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-select
                          label="State*"
                          v-model="stateName"
                          :items="states"
                          outlined
                          item-value="state"
                          item-text="state"
                          class="rounded-xl"
                          @change="fetchDistricts"
                          :rules="[(v) => !!v || 'State is required']"
                          required
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-select
                          label="Select District*"
                          v-model="districtName"
                          :items="districts"
                          outlined
                          class="rounded-xl"
                          item-value="district"
                          item-text="district"
                          @change="fetchCities()"
                          :rules="[(v) => !!v || 'District is required']"
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          v-model="talukTehsil"
                          :value="talukTehsil"
                          label="Taluk / Tehsil"
                          outlined
                          class="rounded-xl"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          label="City/Village*"
                          v-model="cityVillage"
                          :items="cities"
                          outlined
                          class="rounded-xl"
                          item-value="city"
                          item-text="city"
                          :rules="[(v) => !!v || 'City/Village is required']"
                          required
                        >
                        </v-text-field>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          class="rounded-xl"
                          :rules="[
                            (v) => !!v || 'Pincode is require',
                            (v) =>
                              (v && v.length >= 6 && v.length <= 6) ||
                              'Pincode must be 6 digit',
                          ]"
                          @keypress="isNumber($event)"
                          maxLength="6"
                          counter="6"
                          v-model="pinCode"
                          label="Pin Code*"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          class="rounded-xl"
                          v-model="longitude"
                          label="Longitude*"
                          :rules="[(v) => !!v || 'Longitude is required']"
                          required
                     
                        ></v-text-field>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          class="rounded-xl"
                          v-model="latitude"
                          label="Latitude*"
                   
                          :rules="[(v) => !!v || 'Latitude is required']"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          class="rounded-xl"
                          v-model="email"
                          label="E-mail Address*"
                          :rules="[
        (v) => !!v || 'E-mail Address is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail Address must be valid'
    ]"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          class="rounded-xl"
                          v-model="contactNumber"
                          label="Contact Number*"
                          :rules="[
                            (v) => !!v || 'Mobile number is required',
                            (v) =>
                              (v && v.length >= 10 && v.length <= 10) ||
                              'Mobile number must be 10 digit',
                          ]"
                          @keypress="isNumber($event)"
                          maxLength="10"
                          counter="10"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-text-field
                      outlined
                      class="py-0 my-0 rounded-xl"
                      v-model="website"
                      label="Website*"
                      :rules="[
        (v) => !!v || 'Website is required',
        (v) => /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(v) || 'Website must be valid'
    ]"
                      required
                    ></v-text-field>

                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-text-field
                          outlined
                          label="Academic Start Year Month*"
                          rounded
                          class="rounded-xl"
                          type="date"
                          required
                          :rules="[dateRule]"
                          v-model="DOJ"
                        ></v-text-field>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-select
                          label="Select Cluster*"
                          v-model="cluster"
                          :items="clusterData"
                          item-value="name"
                          attach
                          item-text="name"
                          outlined
                          :rules="[(v) => !!v || 'Cluster is required']"
                          required
                          class="rounded-xl"
                        >
                        </v-select>
                      </v-col>
                    </v-row>

                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-select
                          label="Select Brand*"
                          :items="brandData"
                          outlined
                          item-text="name"
                          item-value="name"
                          class="rounded-xl"
                          v-model="brand"
                          :rules="[(v) => !!v || 'Brand is required']"
                          required
                        >
                        </v-select>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-autocomplete
                          v-model="selectedBoard"
                          clearable
                          deletable-chips
                          label="Select Boards*"
                          outlined
                          :rules="[selectedItemsRequired]"
                          required
                          class="rounded-xl"
                          small-chips
                          :search-input.sync="searchBoards"
                          :items="boardsData"
                          multiple
                          item-text="name"
                          item-value="name"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row class="py-0 my-0">
                      <v-col class="py-0 my-0" cols="6">
                        <v-autocomplete
                          v-model="selectedGrade"
                          clearable
                          deletable-chips
                          hide-details
                          label="Select Grade*"
                          :rules="[ (v) => (v && v.length > 0) || 'Grade is required']"
                          required
                          outlined
                          class="rounded-xl"
                          small-chips
                          :search-input.sync="searchGrade"
                          :items="gradesData"
                          multiple
                          item-text="name"
                          item-value="name"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0 my-0" cols="6">
                        <v-autocomplete
                          v-model="selectedSubject"
                          hide-details
                          clearable
                          deletable-chips
                          label="Select Subject*"
                          :rules="[(v) => (v && v.length > 0) || 'Subject is required']"
                          required
                          outlined
                          class="rounded-xl"
                          small-chips
                          :search-input.sync="searchSubject"
                          :items="subjectsData"
                          multiple
                          item-text="name"
                          item-value="name"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-card-title> ASSETS </v-card-title>
                    <v-row class="py-0 my-0">
                      <v-col
                        class="py-0 my-0 d-flex flex-row justify-space-between align-center"
                        cols="6"
                      >
                        <v-card-subtitle class="pa-0-0 ma-0">
                          Laptop/ Desktop available
                        </v-card-subtitle>
                        <v-card width="150px " class="elevation-0">
                          <v-text-field
                            hide-details=""
                            type="number"
                            outlined
                            class="rounded-xl w-150"
                            v-model.number="AssetCount"
                            required
                            :rules="[ (v) => v >= 0 || 'Value must be non-negative' ]"
                          ></v-text-field>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </v-card>
          </v-row>

          <v-dialog style="z-index: 300 !important" fullscreen v-model="viewSchoolDialog" center>
            <v-card>
              <v-row class="primary topfixed mb-8"
                ><v-card-title class="pl-12 mt-3 mb-2"
                  >School View</v-card-title
                >
                <v-spacer></v-spacer>
                <v-card-actions class="mt-3 mb-2 mr-6">
                  <v-btn
                    width="102px"
                    height="48px"
                    outlined
                    rounded
                    text
                    class="accent--text pa-4 mr-1 mt-2"
                    @click="viewSchoolDialog = false"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-row>
              <v-card-text>
                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      readonly
                      outlined
                      class="rounded-xl"
                      v-model="schoolName"
                      label="School Name*"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      readonly
                      outlined
                      class="rounded-xl"
                      v-model="schoolCode"
                      label="School Code"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  readonly
                  outlined
                  class="py-0 my-0 rounded-xl"
                  v-model="address"
                  label="Address"
                ></v-text-field>
                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-select
                      readonly
                      label="Select Country"
                      v-model="countryName"
                      :items="countries"
                      item-value="name"
                      item-text="name"
                      outlined
                      class="rounded-xl"
                    >
                    </v-select>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-select
                      readonly
                      label="State"
                      v-model="stateName"
                      :items="states"
                      outlined
                      item-value="state"
                      item-text="state"
                      class="rounded-xl"
                      required
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-select
                      readonly
                      label="Select District"
                      v-model="districtName"
                      :items="districts"
                      outlined
                      class="rounded-xl"
                      item-value="district"
                      item-text="district"
                      required
                      
                    >
                    </v-select>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      readonly
                      v-model="talukTehsil"
                      :value="talukTehsil"
                      label="Taluk / Tehsil"
                      outlined
                      class="rounded-xl"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-select
                      readonly
                      label="City/Village*"
                      v-model="cityVillage"
                      :items="cities"
                      outlined
                      class="rounded-xl"
                      item-value="city"
                      item-text="city"
                    >
                    </v-select>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      outlined
                      class="rounded-xl"
                      v-model="pinCode"
                      readonly
                      label="Pin Code"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      outlined
                      readonly
                      class="rounded-xl"
                      v-model="longitude"
                      label="Longitude"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      outlined
                      class="rounded-xl"
                      v-model="latitude"
                      readonly
                      label="Latitude"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      outlined
                      class="rounded-xl"
                      v-model="email"
                      readonly
                      label="E-mail Address"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      outlined
                      class="rounded-xl"
                      v-model="contactNumber"
                      readonly
                      label="Contact Number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  outlined
                  class="py-0 my-0 rounded-xl"
                  v-model="website"
                  readonly
                  label="Website"
                ></v-text-field>

                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-text-field
                      outlined
                      label="Academic Start Year Month"
                      rounded
                      readonly
                      class="rounded-xl"
                      type="date"
                      v-model="DOJ"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-select
                      label="Cluster*"
                      v-model="cluster"
                      :items="clusterData"
                      readonly
                      item-value="name"
                      attach
                      item-text="name"
                      outlined
                      required
                      class="rounded-xl"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-select
                      label="Brand"
                      :items="brandData"
                      outlined
                      item-text="name"
                      readonly
                      item-value="name"
                      class="rounded-xl"
                      v-model="brand"
                    >
                    </v-select>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-autocomplete
                      v-model="selectedBoard"
                      clearable
                      deletable-chips
                      readonly
                      label="Boards"
                      outlined
                      class="rounded-xl"
                      small-chips
                      :search-input.sync="searchBoards"
                      :items="boardsData"
                      multiple
                      item-text="name"
                      item-value="name"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="py-0 my-0">
                  <v-col class="py-0 my-0" cols="6">
                    <v-autocomplete
                      readonly
                      v-model="selectedGrade"
                      clearable
                      deletable-chips
                      hide-details
                      label="Select Grade*"
                      outlined
                      class="rounded-xl"
                      small-chips
                      :search-input.sync="searchGrade"
                      :items="gradesData"
                      multiple
                      item-text="name"
                      item-value="name"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="py-0 my-0" cols="6">
                    <v-autocomplete
                      v-model="selectedSubject"
                      hide-details
                      clearable
                      deletable-chips
                      label="Subject"
                      readonly
                      outlined
                      class="rounded-xl"
                      small-chips
                      :search-input.sync="searchSubject"
                      :items="subjectsData"
                      multiple
                      item-text="name"
                      item-value="name"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-card-title> ASSETS </v-card-title>
                <v-row class="py-0 my-0">
                  <v-col
                    class="py-0 my-0 d-flex flex-row justify-space-between align-center"
                    cols="6"
                  >
                    <v-card-subtitle class="pa-0-0 ma-0">
                      Laptop/ Desktop available
                    </v-card-subtitle>
                    <v-card width="150px " class="elevation-0">
                      <v-text-field
                        hide-details=""
                        readonly
                        type="number"
                        outlined
                        class="rounded-xl w-150"
                        v-model.number="AssetCount"
                      ></v-text-field>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- Filter Dialog -->

          <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
            <v-card width="570px" height="100%" class="filterDialogClass">
              <v-row class="mt-4 mx-1">
                <v-col>
                  <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                    Filter
                  </div>
                </v-col>
                <v-col class="d-flex justify-end">
                  <div
                    @click="clearFilter"
                    class="text-body1 font-weight-bold black--text cursor"
                  >
                    CLEAR FILTER
                  </div>
                </v-col>
              </v-row>

              <v-tabs vertical>
                <div class="ml-3 mt-4 mr-4 filterLine">
                  <v-tab
                    class="black--text justify-start"
                    active-class="activeTab"
                    >Brands
                    <span v-if="selectedBrandsFilter.length > 0" class="ml-1"
                      >({{ selectedBrandsFilter.length }})</span
                    ></v-tab
                  >

                  <v-tab
                    class="black--text justify-start"
                    active-class="activeTab"
                    >Clusters
                    <span v-if="selectedClusterFilters.length > 0" class="ml-1"
                      >({{ selectedClusterFilters.length }})</span
                    ></v-tab
                  >

                  <v-tab
                    class="black--text justify-start"
                    active-class="activeTab"
                    >Boards
                    <span v-if="selectedBoardFilter.length > 0" class="ml-1"
                      >({{ selectedBoardFilter.length }})</span
                    ></v-tab
                  >

                  <v-tab
                    class="black--text justify-start"
                    active-class="activeTab"
                    >Subjects
                    <span v-if="selectedSubjectFilter.length > 0" class="ml-1"
                      >({{ selectedSubjectFilter.length }})</span
                    ></v-tab
                  >
                </div>
                <v-tab-item>
                  <v-card flat class="school-filter">
                    <v-card-text>
                      <v-chip-group
                        v-model="selectedBrandsFilter"
                        active-class="secondary"
                        column
                        :multiple="true"
                        @change="getFilterCount"
                      >
                        <v-chip
                          v-for="(brandType, index) in brandData"
                          :key="index"
                          :value="brandType.name"
                          elevated 
                        >
                          {{ brandType.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat class="school-filter">
                    <v-card-text>
                      <v-chip-group
                        v-model="selectedClusterFilters"
                        active-class="secondary"
                        column
                        :multiple="true"
                        @change = "getFilterCount"
                      >
                        <v-chip
                          v-for="(clusterType, index) in clusterData"
                          :key="index"
                          :value="clusterType.name"
                          elevated
                        >
                          {{ clusterType.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat class="school-filter">
                    <v-card-text>
                      <v-chip-group
                        v-model="selectedBoardFilter"
                        active-class="secondary"
                        column
                        :multiple="true"
                        @change = "getFilterCount"
                      >
                        <v-chip
                          v-for="(boardType, index) in boardsData"
                          :key="index"
                          :value="boardType.name"
                          elevated
                        >
                          {{ boardType.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat class="school-filter">
                    <v-card-text>
                      <v-chip-group
                        v-model="selectedSubjectFilter"
                        active-class="secondary"
                        column
                        :multiple="true"
                        @change = "getFilterCount"
                      >
                        <v-chip
                          v-for="(subjectType, index) in subjectsData"
                          :key="index"
                          :value="subjectType.name"
                          elevated
                        >
                          {{ subjectType.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
              <div class="filterDialogButton">
                <v-card-actions class="px-6 pb-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    outlined
                    class="accent--text pa-4"
                    @click="filterDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    :disabled="
                      selectedBrandsFilter.length == 0 &&
                      selectedClusterFilters.length == 0 &&
                      selectedBoardFilter.length == 0 &&
                      selectedSubjectFilter.length == 0
                    "
                    :loading="isFilterLoading"
                    rounded
                    class="accent pa-4"
                    @click="
                      filterSchool(
                        selectedBrandsFilter,
                        selectedClusterFilters,
                        selectedBoardFilter,
                        selectedSubjectFilter
                      ),
                        getFilterCount()
                    "
                    >Apply</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" prepend-inner-icon="mdi-magnify" v-model="search" clearable></v-text-field></v-col> -->
      </v-row>

      <div v-if="!dialog">
        <v-row class="d-flex justify-center my-0 py-0">
          <v-col>
            <div class="text-h6">Schools</div>
          </v-col>

          <v-col cols="10" class="pl-0 ml-0">
            <v-row justify="end" class="mb-1 mt-0">
              <v-text-field
                solo
                label="Search here..."
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                hide-details
                class="search-bar border-button rounded-pill mx-3"
                dense
                clearable
              ></v-text-field>
              <v-btn depressed outlined rounded class="border-button mx-3"
                ><v-icon class="pr-2">mdi-tune</v-icon
                ><span v-if="filterCount > 0" @click="filterDialog = true"
                  >Filter ({{ filterCount }})</span
                >
                <span v-else @click="filterDialog = true">Filter</span></v-btn
              ><v-btn
                class="border-button mx-3"
                :disabled="selected.length == 0"
                rounded
                outlined
                @click="deleteDialog = true"
                ><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn
              >
              <!-- <v-btn class="primary mx-2"
            rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn
                depressed
                outlined
                rounded
                class="border-button mx-3"
                @click="downlaodRoles"
                ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn
              >

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$store.state.role.school.admin"
                    class="accent mx-3 white--text"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    ><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn
                  >
                </template>
                <v-list>
                  <v-list-item @click="createForm">
                    <v-list-item-title>
                      <v-icon class="pr-1">mdi-email-plus-outline</v-icon>CREATE
                      NEW SCHOOL
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="uploadBulkUsers = true">
                    <v-list-item-title>
                      <v-icon>mdi-account-multiple-plus </v-icon>

                      CREATE SCHOOLS IN BULK
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          fixed-header
          height="calc(100vh - 230px)"
          class="rounded-table"
          :search="search"
          v-model="selected"
          :loading="dataTableLoading"
          loading-text="Loading Schools"
          :headers="headers"
          :items="tableData"
          show-select
          :single-select="singleSelect"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 25, 50, 100],
          }"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ getDate(item.created_at) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="$store.state.role.school.admin"
              icon
              class="mr-2 pa-4"
              @click="updateData(item)"
            >
              <img
                width="36px"
                height="36px"
                class="cursor"
                src="../../assets/edit.svg"
              />
            </v-btn>
            <v-btn
              v-if="$store.state.role.school.admin"
              icon
              class="mr-2 pa-4"
              @click="handleClick(item)"
            >
              <img
                width="36px"
                height="36px"
                class="cursor"
                src="../../assets/eye.svg"
              />
            </v-btn>
          </template>
          <template v-slot:no-results>
            <div class="mt-16 pt-16">
              <img 
              src="../../assets/noResultFound.svg"/>
            </div>
          </template>
        </v-data-table>
      </div>

      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"
                ><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar
              >

              <p class="text-h5 pt-4 pb-0">Delete School</p>
              <p
                class="text-disabled grey--text text-subtitle-1"
                color="rgba(0, 0, 0, 0.6)"
                disabled
              >
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn
                  depressed
                  class="background black--text"
                  large
                  width="157px"
                  rounded
                  @click="deleteDialog = false"
                  >CANCEL</v-btn
                >
                <v-btn
                  class="accent white--text"
                  depressed
                  large
                  width="157px"
                  rounded
                  :loading="dLoading"
                  @click="deleteData(selected)"
                  >DELETE</v-btn
                >
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn
                class="primary"
                large
                width="157px"
                rounded
                @click="errorDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="deleteSuccessDialog"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 font-weight-bold py-4">School Deleted</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="deleteSuccessDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="successDialog"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-4">School {{ formbtnValue() }}</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="successDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>
  </div>
</template>
<script>
import AddressController from "@/controllers/AddressController";
import AuthService from "../../services/AuthService";

import SchoolController from "@/controllers/SchoolController";
import BoardController from "@/controllers/BoardController";
import BrandController from "@/controllers/BrandController";
import SubjectController from "@/controllers/SubjectController";
import ClusterController from "@/controllers/ClusterController";
import GradeController from "@/controllers/GradeController";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "@/components/MastersDropdownComponent.vue";
import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../../firebase";
export default {
  name: "SchoolsView",
  components: {
    Notification, MastersDropdownComponent
  },

  data() {
    return {
      filterCount: 0,
      uploadClusters: [],
      uploadSchools:[],
      uploadBrands: [],
      subjectData: [],

      uploadBoards: [],
      uploadSubjects: [],
      uploadBulkUsers: false,
      uploadingUsersDialog: false,
      notificationDialog: false,
      appliedFilter: false,
      options: {},
      unsubscribe: [],
      bulkSchoolsJsonData: [],
      bulkSchoolUploadResponse: [],
      headersresponce: [
        { text: "Index in CSV", value: "schoolIndex" },
        { text: "Status", value: "status" },
      ],

      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      dialog: false,
      errorMessage: "",
      dialogTitle: "Dialog Title",
      input1: "",
      input2: "",
      subjectsData: [],
      deleteDialog: false,
      schoolData: "",
      dLoading: false,
      singleSelect: false,
      isFilterLoading: false,
      longitude: "",
      latitude: "",
      AssetCount: "",
      selected: [],
      clusterData: [],
      filterDialog: false,
      formbtnBool: false,
      loading: false,
      schoolName: "",
      schoolCode: "",
      viewSchoolDialog: false,
      address: "",
      countryName: "India",
      countries: [
        {
          name: "India",
        },
      ],
      stateName: "",
      districtName: "",
      talukTehsil: "",
      cityVillage: "",
      boardsData: [],
      pinCode: "",
      email: "",
      contactNumber: "",
      website: "",
      brandData: [],
      filterData: false,
      DOJ: "",
      cluster: "",
      brand: "",
      selectedBoard: [],
      selectedBrandsFilter: [],
      selectedClusterFilters: [],
      selectedBoardFilter: [],
      selectedSubjectFilter: [],
      gradesData: [],
      dataTableLoading: true,
      headers: [
        { text: "School Code", value: "schoolCode" },
        { text: "School Name", value: "name" },
        { text: "Cluster", value: "cluster" },
        { text: "Brand", value: "brand" },
        { text: "State", value: "state" },
        { text: "City", value: "city" },
        { text: "Area", value: "taluka" },
        // { text: "Created On", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      boards: [
        { name: "Boards 1" },
        { name: "Boards 2" },
        { name: "Boards 3" },
        { name: "Boards 4" },
      ],
      selectedBoards: [],
      searchBoards: "",

      grade: [
        { name: "Grade 1" },
        { name: "Grade 2" },
        { name: "Grade 3" },
        { name: "Grade 4" },
      ],
      selectedGrade: [],
      searchGrade: "",
      subject: [
        { name: "Subject 1" },
        { name: "Subject 2" },
        { name: "Subject 3" },
        { name: "Subject 4" },
      ],
      selectedSubject: [],
      searchSubject: "",

      states: [],
      districts: [],
      cities: [],
      search: "",
      searchBool: false,
      talukas: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
    };
  },
  watch: {

 
    // options: {
    //   handler() {
    //     // console.log(this.options);
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     if(this.filterData){
    //       this.filterSchool(this.selectedBrandsFilter, this.selectedClusterFilters, this.selectedBoardFilter, this.selectedSubjectFilter)
    //     }else if(this.searchBool){
    //       this.searchData(this.search);
    //     }
    //     else{
    //       this.getSchool();
    //     }
    //   },
    //   deep: true,
    // },
    // search(newValue){
    //   // console.log(newValue);
    //     this.searchBool=true
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     this.options.page=1;
    //     this.searchData(newValue);
    //     if(newValue=="" || newValue==null){
    //       this.getSchool();
    //       this.searchBool=false;
    //     }
    // }
  },
  methods: {
areAllElementsPresent(subArray, array) {
  console.log("sub array",subArray);
  console.log("array",array);

  
  // console.log("inside subject check",subArray,array)
  const setOne = new Set(subArray);
  const setTwo = new Set(array);
  return [...setOne].every(element => setTwo.has(element));
},
    async getSubjects() {
      const response = await SubjectController.getSubject();
      console.log("status", response.status)
      if (response.status == 200) {
        if (response.data.subjects.length > 0) {
          this.subjectData = response.data.subjects;
          this.subjectData.forEach((obj)=>this.uploadSubjects.push(obj.name))
        } else {
          this.subjectData = [];
        }
      }
      //console.log("surbject responser", this.subjectData);
    },


    downloadTextFile(text, filename) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    checkMaters(key, word) {
console.log("word",word)
switch (key) {
  case "cluster":
    return this.uploadClusters.includes(word); 
  case "brand":
    return this.uploadBrands.includes(word);
  case "boards":  
  return  this.areAllElementsPresent(word.split(","),this.uploadBoards); 
  case "subjects":
  return  this.areAllElementsPresent(word.split(","),this.uploadSubjects);
  default:
    return true;
}
},


    uploadUsers() {
    return new Promise(async (resolve, reject) => {
      this.uploadingUsersDialog = true;
      this.uploadBulkUsers = false;

      let index = 2;
      const uploadPromises = this.bulkSchoolsJsonData.map(async (school_) => {
        try {
          const response = await SchoolController.createSchool(school_);
          if (response.status == 200) {
            console.log(school_);
            this.bulkSchoolUploadResponse.push({
              schoolIndex: index,
              status: "Uploaded Successfully!",
            });
          } else {
            this.bulkSchoolUploadResponse.push({
              schoolIndex: index,
              status: "Uploaded Failed!",
            });
          }
          index++;
        } catch (error) {
          console.log(error);
          this.bulkSchoolUploadResponse.push({
            schoolIndex: index,
            status: "Upload Failed due to error!",
          });
          index++;
        }
      });

      try {
        await Promise.all(uploadPromises);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },

  handleFileUpload(event) {
    const file = event.target.files[0];
    if (file) {
      this.processCSV(file);
    }
  },

  csvFileToJSON(file) {
    return new Promise((resolve, reject) => {
      let contents = [];
      this.unsubscribe.forEach((obj) => {
        obj();
      });

      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: (results) => {
          try {
            this.content = results;
            this.parsed = true;
            let removedQuestion = [];

            contents = this.content.data;

            let jsonData = [];

            let headers = contents[0];
            for (let i = 1; i < contents.length; i++) {
              let rowData = {};
              let cells = contents[i];
              let shouldIncludeRow = true;

              for (let j = 0; j < cells.length; j++) {
                if (!this.checkMaters(headers[j], cells[j])) {
                  shouldIncludeRow = false;
                  if (!removedQuestion.includes(i)) removedQuestion.push(i);
                  break;
                }

                if (headers[j].includes("pincode")) {
                  rowData[headers[j]] = parseInt(cells[j].trim());
                } else if (
                  headers[j].includes("subjects") ||
                  headers[j].includes("grades") ||
                  headers[j].includes("boards")
                ) {
                  rowData[headers[j]] = cells[j].trim().split(",");
                } else {
                  rowData[headers[j]] = cells[j].trim();
                }
              }

              if (shouldIncludeRow) {
                console.log("row data", rowData);
                jsonData.push(rowData);
              }
            }
            if (removedQuestion.length != 0) {
              let msg = "Rows " + removedQuestion.join(", ") + " will not be uploaded due to blank cell or Wrong Entry";
              this.downloadTextFile(msg, "user uploadReport.csv");
            }

            this.bulkSchoolsJsonData = jsonData;
            resolve();
          } catch (e) {
            console.error("Error in parsing:", e);
            reject(e);
          }
        },
        error: (error) => {
          console.error("Papa Parse error:", error);
          reject(error);
        }
      });
    });
  },
  async processCSV(file) {
    try {
      await this.csvFileToJSON(file);
      await this.uploadUsers();
      console.log("Calling . schools");
      await this.getSchool();
    } catch (error) {
      console.error("Error processing CSV:", error);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async fetchTalukas() {
      const response = await AddressController.getTalukas(this.districtName);
      this.talukas = response.data.data.rows;

      //console.log(this.talukas);
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    downlaodRoles() {
      const fileName = "schools.csv";
      this.downloadCSV(this.schoolData, fileName);
    },

    async fetchDistricts() {
      //console.log(this.personalInfo.state_id);
      const q = query(
        collection(db, "districts"),
        where("state", "==", this.stateName)
      );
      const querySnapshots = await getDocs(q);
      this.districts = [];
      querySnapshots.forEach((doc) => {
        this.districts.push(doc.data());
        // console.log(doc.data());
      });
      // console.log("district",this.districts)

      //console.log(this.districts);
      // this.fetchCities();
    },
    async getBrands() {
      const response = await BrandController.getBrands();
      if (response.status == 200) {
        this.dataTableLoading = false;
        this.brandData = response.data.brands;
        this.brandData.forEach(obj=>this.uploadBrands.push(obj.name))
      } else {
        alert(response);
      }
    },

    async fetchCities() {
      const q = query(
        collection(db, "cities"),
        where("state", "==", this.stateName),
        where("district", "==", this.districtName)
      );
      const querySnapshots = await getDocs(q);
      this.cities = [];
      querySnapshots.forEach((doc) => {
        this.cities.push(doc.data());
        // console.log(doc.data());
      });
      // console.log("district",this.cities)

      //console.log(this.cities);
    },

    async searchData(search) {
      const response = await SchoolController.searchSchool(
        this.pageSize,
        this.page,
        search
      );
      // console.log(response.data);
      // console.log(this.searchBool);
      this.count = response.data.data.count;
      this.tableData = response.data.data.rows;
    },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },

    async fetchStates() {
      var states = [];
      const querySnapshot = await getDocs(collection(db, "states"));
      if (querySnapshot.empty) {
        alert("States Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          states.push(doc.data());
        });
      }
      this.states = states;
      // console.log("state formm base", states)
    },
    async stateNameistricts() {
      var districts = [];
      const querySnapshot = await getDocs(
        collection(db, "states/" + this.stateName + "/districts")
      );

      if (querySnapshot.empty) {
        alert("States Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          districts.push(doc.data());
        });
      }
      this.districts = districts;
      // console.log("districts formm base", districts)
    },

    createForm() {
      (this.schoolName = ""),
        (this.schoolCode = ""),
        (this.brand = ""),
        (this.cluster = ""),
        (this.address = ""),
        (this.contactNumber = ""),
        (this.website = ""),
        (this.countryName = ""),
        (this.cityVillage = ""),
        (this.stateName = ""),
        (this.DOJ = ""),
        (this.email = ""),
        (this.districtName = ""),
        (this.talukTehsil = ""),
        (this.pinCode = ""),
        (this.dialog = true);
      this.formbtnBool = false;
      this.selectedBoard = "";
      this.selectedSubject = "";
      this.selectedGrade = "";
    },

    updateData(item) {
      this.viewSchoolDialog = false;
      this.schoolCode = item.schoolCode;
      this.countryName = item.countryName;
      this.stateName = item.state;
      this.cluster = item.cluster;
      this.districtName = item.district;
      this.talukTehsil = item.taluka;
      this.cityVillage = item.city;
      (this.schoolName = item.name),
        (this.brand = item.brand),
        (this.address = item.address),
        (this.contactNumber = item.contact),
        (this.website = item.website),
        (this.DOJ = item.academicDate),
        (this.email = item.email);
      this.selectedBoard = item.boards;
      this.selectedSubject = item.subjects;
      this.selectedGrade = item.grades;
      this.pinCode = "" + item.pincode;

      this.editId = item.id;
      // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
      console.log("pincode #########", this.pinCode, typeof this.pinCode);
    },

    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },
    async getBoards() {
      const response = await BoardController.getBoards();
      // console.log('boards data',response);
      if (response.status == 200) {
        this.boardsData = response.data.boards;
       
        this.boardsData.forEach(obj=>this.uploadBoards.push(obj.name))
      } else {
        alert("Boards Not Found!!");
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async saveInputs() {
      // checking case for update/create
      console.log("validate flag",this.$refs.form.validate())
      if (this.$refs.form.validate()) {
        this.loading = true;
        var res;
        if (this.formbtnBool == false) {
          const response = await SchoolController.createSchool({
            name: this.schoolName,
            schoolCode: this.schoolCode,
            longitude: this.longitude,
            latitude: this.latitude,
            brand: this.brand,
            cluster: this.cluster,
            address: this.address,
            contact: this.contactNumber,
            website: this.website,
            totalStrength: 5,
            countryName: this.countryName,
            city: this.cityVillage,
            state: this.stateName,
            academicDate: this.DOJ,
            pincode: this.pinCode,
            email: this.email,
            boards: this.selectedBoard,
            subjects: this.selectedSubject,
            grades: this.selectedGrade,
            district: this.districtName,
            taluka: this.talukTehsil,
          });
          // console.log(response);
          res = response;
        } else {
          const response = await SchoolController.updateSchool(
            {
              name: this.schoolName,
              schoolCode: this.schoolCode,
              brand: this.brand,
              cluster: this.cluster,
              address: this.address,
              contact: this.contactNumber,
              website: this.website,
              totalStrength: 5,
              longitude: this.longitude,
              latitude: this.longitude,
              country: this.countryName,
              city: this.cityVillage,
              state: this.stateName,
              email: this.email,
              pincode: this.pinCode,
              academicDate: this.DOJ,
              board: this.selectedBoard,
              subjects: this.selectedSubject,
              grades: this.selectedGrade,
              district: this.districtName,
              taluka: this.talukTehsil,
            },
            this.editId
          );
          // console.log(response);
          res = response;
        }
        // Close the dialog
        this.formbtnBool == false;
        this.dialog = false;
        if (res.status == 200) {
          this.successDialog = true;
        }else if(res.status == 200){
          this.errorDialog = true;
          this.errorMessage = 'School Code Already Exist';
        }
         else {
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getSchool();
        this.loading = false;
      }
    },

    async deleteData(items) {
      // console.log('selected user',items)
      var ids = [];
      items.forEach((doc) => {
        ids.push(doc.id);
      });
      this.dLoading = true;
      const response = await SchoolController.deleteSchool({
        ids: ids,
      });
      if (response.status == 200) {
        this.getSchool();
      } else {
        alert(response);
      }

      this.deleteDialog = false;
      this.dLoading = false;
      this.selected = [];
      this.deleteSuccessDialog = true;
    },

    async getSchool() {
      this.dataTableLoading = true;
      const response = await SchoolController.getSchoolByPagiation(
        this.pageSize,
        this.page
      );
      if (response.status == 200) {
        this.dataTableLoading = false;
        // console.log(response);
        this.schoolData = response.data.schools;
        this.tableData = this.sortByAlphabet(this.schoolData, "name");
        this.count = response.data.count;
        this.fetchStates();
        this.fetchSubjects();
        this.getGrades();
        this.getBoards();
        this.getBrands();
        this.fetchAllClusters();
      }
      // else if (response.status == 404) {
      //   alert(response.result)
      // }
      else {
        this.dataTableLoading = false;
        alert("Something went wrong");
      }
    },


    async fetchSubjects() {
      const response = await SubjectController.getSubject();
      // console.log('subject',response)
      if (response.status == 200) {
        this.subjectsData = response.data.subjects;
      } else {
        alert("subject Not Found!!");
      }
    },

    async fetchAllClusters() {
      const response = await ClusterController.getAllClustersByPagination(
        this.pageSize,
        this.page
      );
      if (response.status == 200) {
        this.dataTableLoading = false;
        this.clusterData = response.data.clusters;
        this.clusterData.forEach((obj)=>{
               this.uploadClusters.push(obj.name)
        })
    
        this.count = response.data.count;
      } else {
        this.dataTableLoading = false;
        alert(response);
      }
    },
    async getGrades() {
      const response = await GradeController.getAllGrades();
      // console.log("grade data",response);
      if (response.status == 200) {
        this.gradesData = response.data.grades;
      } else {
        alert("Levels Not Found!!");
      }
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
    handleClick(item) {
      this.viewSchoolDialog = true;
      this.schoolCode = item.schoolCode;
      this.countryName = item.countryName;
      this.stateName = item.state;
      this.cluster = item.cluster;
      this.districtName = item.district;
      this.talukTehsil = item.taluka;
      this.cityVillage = item.city;
      (this.schoolName = item.name),
        (this.brand = item.brand),
        (this.address = item.address),
        (this.contactNumber = item.contact),
        (this.website = item.website),
        (this.DOJ = item.academicDate),
        (this.email = item.email);
      this.selectedBoard = item.boards;
      this.selectedSubject = item.subjects;
      this.selectedGrade = item.grades;
      this.pinCode = item.pincode;

      this.editId = item.id;
    },
    clearFilter() {
      this.selectedBrandsFilter = [];
      this.selectedClusterFilters = [];
      this.selectedBoardFilter = [];
      this.selectedSubjectFilter = [];
      this.getSchool();
      // this.filterDialog = false;
      this.filterData = false;
      this.appliedFilter = false;
      this.filterCount = 0;
    },
    async filterSchool(
      selectBrands,
      selectCluster,
      selectBoards,
      selectSubject
    ) {
      this.isFilterLoading = true;
      const response = await SchoolController.filterSchool(
        selectBrands,
        selectCluster,
        selectBoards,
        selectSubject
      );
      if (response.status == 200) {
        this.filterDialog = false;
        this.tableData = this.sortByAlphabet(response.data.schools, "name");
        this.count = response.data.count;
        this.filterData = true;
        this.isFilterLoading = false;
        this.appliedFilter = true;
      } else {
        alert(response.alert.error);
        this.isFilterLoading = false;
      }

      // console.log("boards", boardsIds);
      // console.log("brands", brandsIds);
      // console.log("cluster", subjectIds);
      // console.log("subject", clustersIds);
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedBrandsFilter.length > 0) {
        this.filterCount += this.selectedBrandsFilter.length
      }
      if (this.selectedClusterFilters.length > 0) {
        this.filterCount += this.selectedClusterFilters.length
      }

      if (this.selectedBoardFilter.length > 0) {
        this.filterCount += this.selectedBoardFilter.length
      }

      if (this.selectedSubjectFilter.length > 0) {
        this.filterCount += this.selectedSubjectFilter.length 
      }
    },
  },
  computed: {
    dateRule() {
      return (value) => {
        const regex = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
        if (!regex.test(value)) {
          return "Please enter a valid date (DD-MM-YYYY)";
        }

        const selectedDate = new Date(value);
        const currentDate = new Date();
        if (selectedDate > currentDate) {
          return "Selected date must be less than or equal to current date";
        }
        return true;
      };
    },
    selectedItemsRequired() {
            return (v) => (v && v.length > 0) || 'Boards is required';
        },


    filteredBoards() {
      if (this.searchBoards) {
        const regex = new RegExp(this.searchBoards);
        return this.boards.filter((boards) => regex.test(boards.name));
      } else {
        return this.boards;
      }
    },

    filteredGrade() {
      if (this.searchGrade) {
        const regex = new RegExp(this.searchGrade);
        return this.grade.filter((grade) => regex.test(grade.name));
      } else {
        return this.grade;
      }
    },

    filteredSubject() {
      if (this.searchSubject) {
        const regex = new RegExp(this.searchSubject);
        return this.subject.filter((subject) => regex.test(subject.name));
      } else {
        return this.subject;
      }
    },
  },
  created() {
    if (!this.$store.state.role.school.read) {
      this.$router.push("/notFound");
    }
    // this.fetchStates();
    // this.fetchSubjects();
    // this.getGrades();
    this.getSubjects();
    this.getSchool();
    // this.getBoards();
    // this.getBrands()
    // this.fetchAllClusters();
    this.$store.state.breadcrumb = "Schools";
  },
};
</script>

<style scoped>
.fixBar {
  position: fixed;
  z-index: 200;
  top: 0;
  width: 99.8%;
  padding-top: 4px;
}
</style>