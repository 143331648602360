<template>
  <div>
  	
    <Bar :data="chartData" :options="modifiedChartOptions" />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  components: {
    Bar
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            stacked: true,
            title: {
              display: true,
              text: 'Number of Students',
              font: {
        		weight: 'bold', // Make the Y-axis title bold
        		size:'14px'
      			}
            },
            ticks: {
              callback: function(value) {
                return value % 10 === 0 ? value : '';
              },
              stepSize: 10,
            },
            min: 0,
            max: 100,
            grid: {
              display: true, // Keep grid visible by default
            }
          },
          x: {
            stacked: true, // Enable stacking for x-axis
            title: {
              display: true,
              text: 'Grades',
              font: {
            weight: 'bold', // Make the X-axis title bold
            size: '14px'
              }
            },
            grid: {
              display: true, // Keep grid visible by default
            }
          }
        }
      })
    },
    showGrid : {
      type: Boolean,
      default: true,
    }
  },
  computed: {    
    modifiedChartOptions() {
      // Clone the chartOptions to avoid direct mutation of prop
      const options = { ...this.chartOptions };
      if(!this.showGrid){
        // Apply the changes only for this specific instance
        options.scales.y.grid.display = false;
        options.scales.x.grid.display = false;
      }
      return options;
    }
  }
};
</script>
