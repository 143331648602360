<template>
    <div>
      <v-container  elevation="0" class="chart">
        <h5>RESULT</h5>
        <div class="big-dot1"></div>
        <div class="big-dot2"></div>
        <p class="para">Pass - {{stats.countAppraised}}</p>
        <p class="para">Fail - {{stats.countOnPip}}</p>
      </v-container>
      <GChart type="PieChart" :data="chartData" :options="chartOptions" />
    </div>
  
  </template>
  
  <script>
  import { GChart } from "vue-google-charts/legacy";
   
  
  export default {
    name: "CampTotalPieChart",
    components: {
      GChart,
    },
    props: ["stats"],

    data() {
      return {
    
  
        chartData: [],
  
        chartOptions: {
          title: "",
          titleTextStyle: {
            alignment: "start", // Align title to the left
          },
          hAxis: null,
          vAxis: {
            minValue: 0,
            gridlines: { color: "transparent" }, // Remove background lines
            textPosition: "none", // Remove y-axis values
          },
          areaOpacity: 1, // Set the opacity of the area chart to 1
          colors: ["#BBD5F9", "#E7CCF6"], // Set multiple colors for the pie chart
          width: "100%",
          height: "132",
  
          legend: {
            position: "left", // Set the legend position to the left side
          },
          chartArea: {
            top: 12,
            bottom: 10,
            left: 100,
            right: 0,
            width: "120%",
            height: "120%",
            // zIndex: 1, // Set the
          },
          pieSliceTextStyle: {
            fontSize: '20px', // Adjust the font size as desired
            color: 'black',
         
          },
          // slices: {
          //   0: { textStyle: { color: "blue", bold: true } }, // Custom styling for "Assessed" label
          //   1: { textStyle: { color: "purple", italic: true } }, // Custom styling for "Un-assessed" label
          // },
        },
      };
    },
    watch: {
    stats: {
      immediate: true,
      handler(newValue) {
        // console.log("isnide watcher",newValue);
        this.convertDataToChartData(newValue);
      },
    },
  },
  methods:{
    convertDataToChartData(data){
      // console.log("isnide method",data);
      this.chartData=[["",""]]
      this.chartData.push(["", data.countAppraised]);
      this.chartData.push(["", data.countOnPip]);
      this.chartData.push(["", data.countTbd]);

      // this.maxValue=invites.invited
    }
  },
    created() {},
  };
  </script>
  
<style scoped>
.chart {
  position: absolute;
  width: 150px;
  padding-bottom: 0px;
  z-index: 1;
  font-size: 14px;
  background-color: white;
}

.big-dot1 {
  position: absolute;
  z-index: inherit;
  margin-top: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #BBD5F9;
}

.big-dot2 {
  position: absolute;
  z-index: inherit;
  margin-top: 32px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #E7CCF6;
}

.big-dot3 {
  position: absolute;
  z-index: inherit;
  margin-top: 56px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #C8E2ED;
}

.para {
  margin-left: 20px;
  margin-top: 4px;
  font-size: 12px !important;
}

h5 {
  font-size: 12px;
  font-weight: 600;
}
</style>
  <!-- margin-left: 10px; -->