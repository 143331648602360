<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div v-else>
    <div class="background sticky-element pb-4">
    <v-card height="64px" width="100%"
      class="ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Roles</p> <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center " style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg">
          </v-hover>
        </v-badge>
        <div>

          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="px-8">

      <!-- Notification Dialog -->
      <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
        <Notification @close-dialog="notificationDialog = false" />
      </v-dialog>

      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Invite Sent</p>
              <v-btn class="accent" large width="157px" rounded @click="getRoles">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="userRemvoeDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">User Removed</p>
              <v-btn class="accent" large width="157px" rounded @click="userRemvoeDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Role Deleted</p>
              <v-btn class="accent" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-4 pb-0">Delete Role</p>
              <p class="text-disabled grey--text text-subtitle-1" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the Role and users in it.
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="background black--text" large width="157px" rounded
                  @click="deleteDialog = false">CANCEL</v-btn>
                <v-btn class="accent white--text" depressed large width="157px" rounded :loading="dLoading"
                  @click="this.deleteRole">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="inviteDialog" class="cdz" max-width="968px">
        <v-card height="685px" width="100%" class="ma-0 pa-0 rounded-t-lg " id="myScroll">
          <v-card elevation="0" height="40px"
            class="w-100 outlined white--text d-flex pl-8 pr-2 align-center justify-space-between primary rounded-t-lg card-header-layout">
            <div class="text--primary text-h6 font-weight-medium">
              INVITE USER
            </div>
            <v-btn @click="onCancel" icon large>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card>
          <div class="px-8 pt-4">
            <v-card-subtitle class="ma-0 pa-0 mb-4 font-weight-medium d-flex justify-space-between align-center">
              <v-btn-toggle
              v-model="inviteUserTab"
              mandatory
              dense
              rounded
              small>
              <v-btn
              width="145px"
              min-height="31px"
              :class="
              inviteUserTab == 0
                    ? 'btn-color white--text text-subtitle-2 '
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
              Single
            </v-btn>
            <v-btn
                width="145px"
                min-height="31px"
                :class="
                  inviteUserTab == 1
                    ? 'btn-color white--text ttext-subtitle-2'
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
              Bulk
            </v-btn>
            </v-btn-toggle>
            <div class="font-weight-light d-flex align-center" v-if="inviteUserTab == 1 && parsedData.length > 0">
                <img src="../assets/attach-solid.png">
                <div class="uplaodbtn2 pl-2 text-subtitle-1 accent--text " style="text-decoration: none;">
                      UPLOAD AGAIN
                      <input
                        type="file"
                        @change="handleFileUpload"
                        name="upload_csv"
                      />
                    </div>
            </div>
            </v-card-subtitle>
            <div v-if="inviteUserTab == 0">
              <v-form ref="inviteForm" @submit.prevent="inviteUsersToRole">
             <v-row>
              <v-col cols="6" md="6" sm="6">
                <v-autocomplete class="rounded-xl" hide-details label="Select Cluster*" v-model="roleUser.clusterName"
                  :items="clusterData" item-value="name" attach item-text="name" outlined
                  :rules="[(v) => !!v || 'Cluster is required']" required @change="getSchool()">
                </v-autocomplete>
              </v-col>
              <v-col cols="6" md="6" sm="6">
                <v-autocomplete :disabled="roles.clusterName == 'All Clusters'" hide-details
                  label="Select School*" outlined v-model="roleUser.schoolName" required
                  :rules="[v => !!v && v.length > 0|| 'School is required']" class="rounded-xl" :items="schools"
                  attach item-text="name" item-value="name">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="6" sm="6">
                <v-text-field class="rounded-xl" v-model="roleUser.name" label="Name*" outlined hide-details
                :rules="[(v) => !!v || 'Name is required',
                (v) => /^[a-zA-Z '-]{2,25}$/.test(v) ||
                'This value must contains alphabets']" required></v-text-field>
              </v-col>
              <v-col cols="6" md="6" sm="6">
                <v-text-field
                  class="rounded-xl"
                  v-model="roleUser.email"
                  label="Email*"
                  outlined
                  hide-details
                  :rules="[(v) => !!v || 'Email is required', (v) => !roleUser.emailError || roleUser.emailError]"
                  required
                  @keydown.enter="inviteUsersToRole"
                ></v-text-field>
                <div v-if="roleUser.emailError" class="error--text">{{ roleUser.emailError }}</div>

              </v-col>
              </v-row>
              <v-row class="pb-4">
                <v-col class="d-flex justify-end">

                <v-btn
                  v-if="!isValidEmail(roleUser.email)"
                  rounded depressed
                  class="accent pl-4 pr-4"
                  @click="inviteUsersToRole"
                  :disabled="!isValidEmail(roleUser.email)"
                >
                ADD
                </v-btn>
                <v-btn color="primary"
                  v-else
                  rounded
                  outlined
                  dark
                  class="accent--text disabled-visible"
                  @click="inviteUsersToRole"
                  >ADD</v-btn>
                </v-col>
              </v-row>
            </v-form>
              <v-divider></v-divider>
            <!-- user list -->
            <v-card v-for="(user, i) in invitedUsersList" :key="i" width="100%" height="70px" class="elevation-0 pa-0 mr-4 py-0">
              <v-row class="ma-0 pa-0 h-100">
                <v-col class="justify-center d-flex flex-column pa-0" sm="5" cols="5" md="5">
                  <div class="text-body-1"><b>{{ user.name }}</b></div>
                  <div class="text-body-1">{{ user.email }}</div>
                </v-col>
                <v-col sm="3" cols="3" md="3" class="d-flex align-center">
                  {{user.clusterName}}
                </v-col>
                <v-col sm="3" cols="3" md="3" class="d-flex align-center">
                  {{user.schoolName}}
                </v-col>
                <v-col cols="1" md="1" sm="1" class="justify-end d-flex">
                  
                  <v-btn x-small @click="removeUser(i,'single')" :loading="user.loading == 'sk'" class="rounded-xl" outlined rounded color="red">
                  
                  <img src="../assets/u_trash.png" class="rounded-xl mx-2" width="18" height="18">
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <!-- user list end here -->
          
          <v-divider v-if="invitedUsersList.length > 0"></v-divider>
          <v-card-actions class="py-4 justify-end">
            <v-btn
              rounded depressed
              @click="onInvite"
              class="accent pl-4 pr-4"
              :disabled="invitedUsersList.length <= 0"
            >
              Send Invite
            </v-btn>
        </v-card-actions>
        </div>
        <div  v-if="inviteUserTab == 1">
          <div v-if="parsedData.length <= 0">
          <v-card
            elevation="0"
            class="w-100 pa-4 custom-dashed-border d-flex flex-column"
            >
            <div class="d-flex justify-end">
              <v-btn class="ma-2" outlined @click="downloadFile">
                
                <img src="../assets/download_file.png" class="rounded-xl mx-2" width="18" height="18">
                Download Template
              </v-btn>
            </div>
            <div class="text-center flex-grow-1 d-flex flex-column justify-center align-center">
              <img src="../assets/xlsx_icon.png">
              <div class="font-weight-light">
                Drag and drop a file or
                <div class="uplaodbtn2">
                      browse file
                      <input
                        type="file"
                        @change="handleFileUpload"
                        name="csv"
                      />
                    </div>
              </div>
              <p class="grey--text">
                (Supported Only .CSV File)
              </p>
            </div>

          </v-card>
          </div>
          <div v-else>
            <v-divider></v-divider>
          <v-card v-for="(user, i) in parsedData" :key="i" width="100%" height="70px" class="elevation-0 pa-0 mr-4 py-0">
              <v-row class="ma-0 pa-0 h-100">
                <v-col class="justify-center d-flex flex-column pa-0" sm="5" cols="5" md="5">
                  <div class="text-body-1"><b>{{ user.name }}</b></div>
                  <div class="text-body-1">{{ user.email }}</div>
                </v-col>
                <v-col sm="3" cols="3" md="3" class="d-flex align-center">
                  {{user.clusterName}}
                </v-col>
                <v-col sm="3" cols="3" md="3" class="d-flex align-center">
                  {{user.schoolName}}
                </v-col>
                <v-col cols="1" md="1" sm="1" class="justify-end d-flex">
                  
                    <v-btn x-small @click="removeUser(i,'bulk')" :loading="user.loading == 'sk'" class="rounded-xl"  rounded outlined color="red">

                  <img src="../assets/u_trash.png" class="rounded-xl mx-2" width="18" height="18">
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-divider></v-divider>
          <div class="my-4 d-flex justify-end">
            <v-btn
              rounded depressed
              @click="onInvite"
              class="accent pl-4 pr-4"
              :disabled="parsedData.length <= 0"
            >
              Send Invite
            </v-btn>
          </div>
        
        
        </div>

        </div>
          <!-- <v-btn @click="onInvite"></v-btn> -->
        </v-card>
      </v-dialog>
      <v-dialog v-model="inviteUserSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Invitation Sent</p>
              <v-btn class="accent" large width="157px" rounded @click="inviteUserSuccessDialog=false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-row justify="space-between" class="my-0 mt-4 py-0">
        <v-col cols="4" sm="4" md="4" class="pb-0 pt-2">
          <div class="text-h6">
            Roles, Access & Permission
          </div>
        </v-col>

        <v-col cols="8" sm="8" class="d-flex flex-row justify-end py-0">
          
          <v-text-field hide-details solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
            class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
          <v-btn depressed outlined rounded class="border-button mx-3" @click="downloadData"><v-icon>mdi-import
              mdi-rotate-90</v-icon></v-btn>
          <v-btn v-if="$store.state.role.rolesAndPrivilage.admin" class="accent ml-3 pl-4 pr-6" @click="moveToCreate"
            rounded depressed><v-icon class="pr-2">mdi-plus</v-icon>{{ update ? "update" : "create" }}</v-btn>
        </v-col>
      </v-row>
    </div>
    </div>
    <div class="px-10" style="width: auto; display: inline-block;">
    <v-card class="rounded-card primary-border ">
      <!-- <v-tabs class="border" v-model="activeTab">
        <v-tab class="custom-tab"  active-class="custom-active-tab">All Roles</v-tab>
      <v-tab class="custom-tab" active-class="custom-active-tab">All Users</v-tab>
      </v-tabs> -->
      
    <v-btn-toggle
      v-model="activeTab"
      mandatory
      dense
      rounded>
      <v-btn
          width="145px"
          :class="
          activeTab == 0
                    ? 'btn-color white--text text-subtitle-2 '
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
        All Roles
      </v-btn>
      <v-btn
                width="145px"
                @click="getAllRoleUsers"
                :class="
                  activeTab == 1
                    ? 'btn-color white--text ttext-subtitle-2'
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
                All Users
              </v-btn>
    </v-btn-toggle>
    </v-card>
  </div>

      <div justify="space-between" class="my-0" v-if="activeTab === 0">
        <div class="w-100 d-flex flex-wrap pl-8" >
          <v-card v-for="(role, index) in roles" :key="index" min-height="196px"
            class="elevation-0 pa-1 mr-4 mt-4 br-8 cardBorder">

            <div class="d-flex flex-column pa-4 w-100 d-justify-evenly">
              <div >
                <v-card-title @click="editUsers(role)">
                <!-- <v-card-title> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="overflow-container cursor">{{ role.roleName }}</div>
                      <v-btn
                      outlined
                      icon
                      rounded class="rounded-xl"
                      v-if="$store.state.role.rolesAndPrivilage.admin"
                      @click="moveToUpdate(role);"
                      >
                      <img src="../assets/pen.png">
                    </v-btn>
                    </template>
                    <div>{{ role.roleName }} </div>
                  </v-tooltip>

                </v-card-title>
              </div>
              <div class="d-flex align-center justify-space-between mt-4 mb-4 pt-4">
                <div class="d-flex">
                  <!-- <v-icon>mdi-account</v-icon> -->
                  <img class="cursor" src="../assets/All_User.png">
                  <v-card-subtitle>
                    <v-tooltip bottom style="z-index:999;">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="cursor text-body-1">
                          {{ role.users.filter(user => user.status !== "INVITED" && user.status !== "INACTIVE").length }}
                          users
                        </span>
                      </template>
                      <div v-html="getNames(role.users.filter(user => user.status !== 'INVITED' && user.status !== 'INACTIVE'))"></div>
                    </v-tooltip>
                  </v-card-subtitle>
                </div>
                <div class="d-flex flex-row-reverse">
                  <v-card-subtitle>
                    <v-tooltip bottom style="z-index: 999;">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="cursor text-body-1">
                          {{
    role.users.filter((user) => user.status == "INVITED").length
  }}
                          Invited
                        </span>
                      </template>
                      <div v-html="getNames(role.users.filter((user) => user.status == 'INVITED'))"></div>
                    </v-tooltip>
                  </v-card-subtitle>
                  <img class="cursor" src="../assets/u_user.png">
                </div>
              </div>
              <div class="ma-0 d-flex justify-space-between pt-4">
                <div>
                  <v-btn v-if="$store.state.role.rolesAndPrivilage.admin" 
                        @click="deleteRoleDialog(role)" color="red" rounded class="rounded-lg"  outlined>
                    <img src="../assets/u_trash.png">
                  </v-btn>
                </div>
                <div>
                  <v-btn
                  v-if="$store.state.role.rolesAndPrivilage.admin" class="ma-0 pa-4 accent--text" @click="
                  inviteUsers(role);
                roleId = role.id;
                "
                outlined
                rounded
                color="primary"
                dark
                >
              Invite User
              </v-btn>
              
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="d-flex align-center mb-4" v-if="activeTab == 1">
        <
        <div class="w-100 px-8">
          <v-data-table
          fixed-header
          height="calc(100vh - 290px)"
          :loading="isLoading"
          :headers="headers"
          :items="allUsers"
          item-key="userId"
          :search="search"
          v-model ="selectedUser"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100],
          }"
          class="cursor usertable rounded-table"
          >
          </v-data-table>
        </div>
      </div>
    </div>
</template>



<script>
import RolesController from "@/controllers/RolesController";
import SchoolController from "@/controllers/SchoolController"
import AuthService from "../services/AuthService";
import Notification from "./Notification";
import { email } from "vuelidate/lib/validators";
import ClusterController from "@/controllers/ClusterController";
import axios from "axios";
import Papa from "papaparse";


export default {
  name: "RoleView",
  components: {
    Notification
  },
  data() {
    return {
      activeTab: 0,
      inviteUserTab:0,
      notificationDialog: false,
      preloader: true,
      inviteDialog: false,
      deleteSuccessDialog: false,
      emailList: [],
      tag: "",
      roles: [],
      selectedItem: null,
      search: "",
      storedRole: [],
      deleteDialog: false,
      roleId: "",
      dLoading: false,
      inviteLoading: false,
      rmUserLoading: false,
      successDialog: false,
      userRemvoeDialog: false,
      update: false,
      users: [],
      selectedRoleId: "",
      invited: "",
      regUsers: "",
      headers: [
        { text: "Name", value: "name"},
        { text: "Email", value: "email"},
        { text: "Role", value: "roleName"},
        { text: "Cluster", value: "clusterName"},
        { text: "Schools", value: "schoolName"},
        { text: "Status", value: "status"},
      ],
      allUsers:[],
      selectedUser:[],
      isLoading:false,
      isToggled:false,
      roleUser:{
        clusterName: '',
        schoolName: '',
        name: '',
        email: '',
        emailError: null,
      },
      clusterData: [
        {
          name: "All Clusters",
          id: "all",
        },
      ],
      schools: [
        { name: "All" }
      ],
      invitedUsersList:[],
      isInviteUserLoading:false,
      inviteUserSuccessDialog:false,
      parsedData:[],
      options: {},
      count :0,
      singleSelect: false,
      emailError:"",
      isEmailError:false,

    };
  },

  computed: {
    checkAllEmail() {
      return this.emailList.some(email => !this.isValidEmail(email));
    }
  },

  methods: {
    async getAllRoleUsers(){
      const response = await RolesController.getAllRoleUsers();
      console.log('data',response.data.data)
      console.log('status',response.status)
      if (response.status == 200) {
        this.isLoading=false;
        this.allUsers = response.data.data;
        
      } else {
        alert("Failed");
      }
      
    },
    handleFileUpload(event) {
      console.log('upload again')
      const file = event.target.files[0];
      if (file && file.type.includes('csv')) {
        this.parseCSV(file);
      } else {
        alert('Please upload a valid CSV file.');
      }
    },
    parseCSV(file) {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          const newData = results.data.filter(row => {
            // Filter out rows where all values are null or empty strings
            return Object.values(row).some(value => value !== null && value !== '');
          });

          // Append new data to the existing parsedData
          this.parsedData = [...this.parsedData, ...newData];

          console.log('parsed Data', this.parsedData);
        },
        error: (error) => {
          console.error(error.message);
        }
      });
    },
    async downloadFile() {

      let fileName = 'Hubble STAR - Invite User Sample File.csv'
      try {
        const response = await axios.get(`https://firebasestorage.googleapis.com/v0/b/vgos-sss-stag.appspot.com/o/invite_users%2F${fileName}?alt=media`, {
          responseType: 'blob' // Important
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
      }

    },
    onCancel() {
    this.inviteDialog = false;

    // Reset form fields
      this.roleUser = {
        clusterName: '',
        schoolName: '',
        name: '',
        email: '',
        emailError: null
      };

      // Reset invited users list
      this.inviteUserTab = 0;
      this.invitedUsersList = [];
      this.parsedData=[];

      // Reset form validation state
      this.$nextTick(() => {
        if (this.$refs.inviteForm) {
          this.$refs.inviteForm.resetValidation();
        }
      });
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    async inviteUsersToRole() {
      try {
        const emailExistsInList = this.invitedUsersList.some(user => user.email === this.roleUser.email);

        if (emailExistsInList) {
          this.roleUser.emailError = "Email already exists in the invited users list!!";
          return;
        }
        const response = await RolesController.checkUserExists(
          this.roleId,[this.roleUser.email]);

        if (!response.data.flag) {
          
          this.roleUser.emailError = "Email already exists!!";
        } else {
          this.roleUser.emailError = null;
          if (this.isValidEmail(this.roleUser.email) && this.$refs.inviteForm.validate()) {
            this.invitedUsersList.push({
              clusterName: this.roleUser.clusterName,
              schoolName: this.roleUser.schoolName,
              name: this.roleUser.name,
              email: this.roleUser.email,
            });

            this.roleUser = {
              clusterName: '',
              schoolName: '',
              name: '',
              email: '',
              emailError: null
            };
            this.$refs.inviteForm.resetValidation();
          }
        }
      } catch (error) {
        console.error(error);
        
        this.roleUser.emailError = "An error occurred while checking the email";
      }
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();
      if (response.data.flag) {
        console.log(response); 

        var data = response.data.clusters;
        this.clusterData = [...this.clusterData, ...data];
      } else {
        alert(response.data.error);
      }
    },
    async getSchool() {
      console.log(this.roleUser.clusterName)
      if (this.roleUser.clusterName != 'All Clusters') {
        var temp = [this.roleUser.clusterName]
        this.schools = [];
        const response = await SchoolController.getClusterSchool(temp);
        console.log("school", response);
        if (response.data.flag) {
          const schools = response.data.schools;
          this.schools = ['All', ...schools];

        } else {
          alert(response.data.error);
        }
      }
      else {
          this.schools = [{ name: 'All', id: null }];
      }
    },
    toggle() {
      this.isToggled = !this.isToggled;
    },
    editUsers(role) {
      
      this.$router.push({
        name: 'roleUserView',
        params: { role }, // Dynamic ID in params
         
      });
    },
    addEmail(e){
     var newValue = e.target._value
     console.error("new value",newValue);
          this.emailList.push(this.tag); 
        this.tag = "";
    },

    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    deleteRoleDialog(item) {
      
      this.deleteDialog = true;
      this.selectedItem = item;

    },
    convertToCSV(jsonData) {
      const csvRows = [];

      for (const item of jsonData) {
        csvRows.push("***************************************************************************************************")
        csvRows.push("***************************************<b>" + item.roleName + "</b>*******************************************")
        csvRows.push("***************************************************************************************************")
        for (const key in item) {
          if (key == 'users') {
            var invitedData = 'Invited Users , '
            var regData = 'Users , '

            item['users'].forEach((obj) => {

              obj.status == 'INVITED' ? invitedData = invitedData + obj.email + ',' : regData = regData + obj.email + ","
            })
            csvRows.push(invitedData);
            csvRows.push(regData);
          }
          else if (typeof item[key] === 'object') {
            const subKeys = Object.keys(item[key]);
            const subValues = subKeys.map(subKey => item[key][subKey]);
            const csvRow = `"${key}", "${subKeys.join('","')}", "${subValues.join('","')}"`;
            csvRows.push(csvRow);
          } else {
            const csvRow = `"${key}", "${item[key]}"`;
            csvRows.push(csvRow);
          }
        }
      }

      return csvRows.join('\n');
    }
    ,
    downloadCSV(csvData, fileName) {
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) {
        // For IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    downloadData(){
      let csvData;
      let fileName;
      if(this.activeTab == 1){
        fileName = 'role_users.csv';
        csvData = this.convertToCSV(this.allUsers);
      }
      else{
        fileName = 'roles.csv';
        csvData = this.convertToCSV(this.roles);
      }
      
      this.downloadCSV(csvData, fileName);
    },
    async deleteRole() {
      var role = this.selectedItem;
      this.CLoading = true;
      const response = await RolesController.deleterole(role, role.id);
      if (response.status == 200) {
        this.CLoading = false;
        this.deleteDialog = false;
        this.deleteSuccessDialog = true;

        this.$store.state.updateRole = null
        this.getRoles();

      } else {
        alert("Failed");
      }
      
    },

    moveToUpdate(data) {
      this.$store.state.updateRole = data;
      this.$router.push("/update-role")
    },
    checkEmail(email) {
      var found = false;
      this.roles.forEach((role) => {
        role.users.forEach((user) => {
          if (user.email == email) {
            var status = ""
            if (user.status == 'INVITED') {
              status = "invited";
            }

            alert(email + " is already " + status + " as a " + role.roleName)
            found = true
            return found;
          }
        })

      })
      return found;
    },
    getNames(name) {
      let names = "";
      for (let i = 0; i < name?.length; i++) {
        names = names + name[i].email + "<br>"

      }
      
      return names
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    inviteUsers(role) {
      
      this.inviteDialog = true;
      this.users = role.users;
      this.selectedRoleId = role.id;
    },
    moveToCreate(id) {
      this.$router.push({
        path: "/createRole",

      });
    },

    // async removeUser(data, index) {
    //   data.loading = "sk";

    //   const resonse = await RolesController.deleteUser({
    //     id: data.uid,
    //     email: data.id,
    //     roleId: this.selectedRoleId,
    //   });

    //   console.log("removing user", resonse);
    //   if (resonse.data.flag) {
    //     this.userRemvoeDialog = true;
    //     this.users.splice(index, 1);
    //   } else {
    //     alert("Failed");
    //   }
    //   data.loading = true;
    // },
    removeUser(index,type) {
      
      if(type == 'single')
        this.invitedUsersList.splice(index, 1);
      else
        this.parsedData.splice(index, 1);
    },
    userLists(data) {
      let name = "";
      data.forEach((d) => {
        name = d.name + ", ";
      });
      return name;
    },
    async onInvite() {
      let userList = this.inviteUserTab === 0 ? this.invitedUsersList : this.parsedData;

      this.isInviteUserLoading = true;

      try {
        const response = await RolesController.inviteRole({
          invitedUserList: userList,
          roleId: this.roleId,
        });

        if (response.data.flag) {
          this.invitedUsersList = [];
          this.inviteDialog = false;
          this.inviteUserSuccessDialog = true;
          this.getRoles();
        } else {
          console.error('Error inviting users:', response.message || response);
        }
      } catch (error) {
        console.error('Error inviting users:', error);
      } finally {
        this.isInviteUserLoading = false;
      }
    },
    invite() {
      this.inviteDialog = true;
    },
    removeTag(index) {
      this.emailList.splice(index, 1);
    },
    addTag() {
      if (this.tag && !this.emailList.includes(this.tag)) {
        this.emailList.push(this.tag);
        this.tag = "";
      }
    },


    async getRoles() {
      this.successDialog = false;
      const response = await RolesController.getRoles(this.roles);
      
      if (response.data.flag) {
        this.roles = response.data.data;
        this.storedRole = response.data.data;
        this.preloader = false;
      } else {
        this.preloader = false;
        alert("Some thing went wrong in roles");
      }
    },
  },

  mounted() {
    // console.log('allUsers',this.allUsers)
    this.getRoles();
    this.fetchAllClusters();

  },
  created() {
    this.$store.state.breadcrumb = "Roles";
  },

  watch: {
    tag(newValue) {
     
      if (newValue.indexOf(",") != -1) {
        var temp = newValue.split(",")[0];
        if (temp != "") {
          this.emailList.push(temp);
        }
        this.tag = "";
      }
      
    },
    search(newValue) {
    if (this.activeTab === 0) {
      if (newValue === '') {
        this.roles = this.storedRole;
      }
      else if (this.search == null) {
        this.search = ' ';
      }

      else {
        const lowercaseNewValue = newValue.toLowerCase();
        this.roles = this.storedRole.filter((role) =>
          role.roleName.toLowerCase().includes(lowercaseNewValue)
        );
      }
    } else if (this.activeTab === 1) {
      if (newValue === '') {
        this.getAllRoleUsers();
      } else {
        const lowercaseNewValue = newValue.toLowerCase();
        this.allUsers = this.allUsers.filter((user) =>
          user.name.toLowerCase().includes(lowercaseNewValue) ||
          user.email.toLowerCase().includes(lowercaseNewValue) ||
          user.roleName.toLowerCase().includes(lowercaseNewValue) ||
          user.clusterName.toLowerCase().includes(lowercaseNewValue) ||
          user.schoolName.toLowerCase().includes(lowercaseNewValue) ||
          user.status.toLowerCase().includes(lowercaseNewValue)
        );
      }
    }
  },
  activeTab(newValue) {
    this.search = '';
    if (newValue === 1) {
      this.getAllRoleUsers();
    } else {
      this.getRoles();
    }
  }
},

};
</script>


<style scoped>

.v-card.cardBorder {
  width: 380px;
}
.v-card__title {
  padding: 0;
  justify-content: space-between;
}
.v-card__subtitle {
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 8px;
}
.square-btn {
  width: 36px; /* Adjust the size as needed */
  height: 36px;
  border-radius: 0; /* Remove border radius for a square shape */
}
.rounded-card {
  border-radius: 20px; /* Adjust the radius as needed */
  overflow: hidden; /* Ensure content stays within rounded corners */
}
.card-section {
  padding: 16px; /* Adjust the padding as needed */
}
.primary-border {
  border: 1px solid #864F20; /* Example border color */
}
.custom-tab {
  color: black; /* Change text color */
}
.custom-active-tab {
  background-color: #DBC8BA !important; /* Change background color */
}
.card-header-layout {
  background-color: #D8D5C3;
  min-height: 56px;
}
.custom-dashed-border {
  border: 2px dashed #9e9e9e;
  min-height: 494px;
  border-radius: 8px; 
}
.text-no-wrap {
  text-decoration: none;
}
</style>