<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Demo Topics
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>

        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Brands</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg" />
          </v-hover>
        </v-badge>
        <div>
          <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad px-8">
      <v-row>
        <v-col>
          <v-dialog max-width="887px" v-model="dialog" class="cdz" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="primary mb-8">
                  {{ formbtn() }} Topic</v-card-title>

                <v-card-text class="px-6 pb-0">
                  <v-row>
                    <v-col cols="4">
                      <v-select label="Select Level*" :items="levelsData" hide-details outlined v-model="levelName"
                        item-text="name" item-value="name" class="rounded-xl"
                        :rules="[(v) => !!v || 'Level name is required']" required v-on:change="setGradesByLevel">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select label="Select Grade*" :items="gradesDataByLevel" hide-details outlined
                        v-model="gradeName" item-text="name" item-value="name" class="rounded-xl"
                        :rules="[(v) => !!v || 'Grade name is required']" required v-on:change="setSubjectsByGrade">
                      </v-select>
                    </v-col>
                    <v-col cols="4">


                      <v-select label="Select Subject*" :items="subjectsDataByGrades" hide-details outlined
                        v-model="subjectName" item-text="name" item-value="name" class="rounded-xl"
                        :rules="[(v) => !!v || 'Subject name is required']" required>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field hide-details outlined class="rounded-xl" v-model="unitName" label="Enter Unit*"
                        :rules="[(v) => !!v || 'Unit is required']"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field hide-details outlined class="rounded-xl" v-model="chapterName"
                        label="Enter Chapter*" :rules="[(v) => !!v || 'Chapter Name is required']"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field hide-details outlined class="rounded-xl" v-model="topicName" label="Topic Name*"
                        :rules="[(v) => !!v || 'Topic name is required']" required></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="mt-4 d-flex flex-row justify-space-between w-100">
                    <div class="font-weight-regular text-subtitle-2 text--primary">
                      Sub - Topics Name*
                    </div>
                    <div class="cursor blue--text font-weight-medium text-subtitle-2"></div>
                    <div class="cursor blue--text mb-2 font-weight-medium text-subtitle-2"
                      :class="subTopics.length != 0 ? 'mt-2' : ''" @click="addSubTopic(subTopics.length - 1)">
                      <v-icon>mdi-plus</v-icon> SUB TOPIC
                    </div>
                  </div>
                  <div class="subTopic c-border d-flex flex-column px-4 py-4 rounded-xl border-x border-y overflow-y-auto">
                    <div v-for="(subtopic, index) in subTopics" :key="index" class="mb-2">
                      <input :id="'subTopicInput' + index" :ref="`subTopicInput${index}`"
                        @keydown.enter="$event.target.blur()" class="border-style-solid mr-2  input-tag-width"
                        v-model="subTopics[index]" type="text"
                        :class="subTopics.length == 0 ? 'border-style-solid-inempty' : ''" />
                      <v-icon @click="deleteSubTopic(index)" class=" cursor" color="red">mdi-close</v-icon>
                    </div>
                    <!-- <v-btn small  @click="addSubTopic(subTopics.length-1)" text class="fit-content blue--text"><v-icon>mdi-plus</v-icon>SUB TOPIC</v-btn> -->
                  </div>

                  <!-- <v-text-field
                    v-for="(subtopic, index) in subTopics"
                    :key="index"
                    hide-details
                    outlined
                    class="rounded-xl mb-4"
                    v-model="subTopics[index]"
                    label="Enter Sub-topic Name*"
                    :rules="[(v) => !!v || 'Topic name is required']"
                    required
                  ></v-text-field> -->
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="accent--text pa-4"
                    @click="dialog = false">Cancel</v-btn>
                  <v-btn width="102px" height="48px" :loading="loading" rounded @click="saveInputs"
                    class="accent pa-4">{{
          formbtn() }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" prepend-inner-icon="mdi-magnify" clearable v-model="search"></v-text-field></v-col> -->
      </v-row>

      <!-- filter dialog -->
      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>

          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <v-tab class="black--text justify-start" active-class="activeTab">Subjects
                <span v-if="selectedSubjectFilter.length > 0" class="ml-1">({{ selectedSubjectFilter.length
                  }})</span>
              </v-tab>
              <v-tab class="black--text justify-start" active-class="activeTab">Levels
                <span v-if="selectedLevelFilter.length > 0" class="ml-1">({{ selectedLevelFilter.length
                  }})</span>
              </v-tab>
              <v-tab class="black--text justify-start" active-class="activeTab">Grades
                <span v-if="selectedGradeFilter.length > 0" class="ml-1">({{ selectedGradeFilter.length
                  }})</span>
              </v-tab>
            </div>
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedSubjectFilter" active-class="secondary" column :multiple="true"
                    @change="getFilterCount">
                    <v-chip v-for="(subject, index) in subjectsData" :key="index" :value="subject.name" elevated>
                      {{ subject.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedLevelFilter" active-class="secondary" column :multiple="true"
                    @change="getFilterCount">
                    <v-chip v-for="(level, index) in levelsData" :key="index" :value="level.name" elevated>
                      {{ level.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group v-model="selectedGradeFilter" active-class="secondary" column :multiple="true"
                    @change="getFilterCount">
                    <v-chip v-for="(grade, index) in gradesData" :key="index" :value="grade.name" elevated>
                      {{ grade.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-0">
              <v-spacer></v-spacer>
              <v-btn rounded outlined class="accent--text pa-4" @click="filterDialog = false">Cancel</v-btn>
              <v-btn :disabled="selectedLevelFilter.length == 0 &&
          selectedGradeFilter.length == 0 &&
          selectedSubjectFilter.length == 0
          " rounded class="accent pa-4" @click="getFilterCount(); getTopics();">Apply</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog max-width="887px" class="cdz" v-model="uploadTopicsUsers" center>
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="primary mb-8">Upload Topics in Bulk</v-card-title>

            <div class="d-flex justify-center mb-6 bg-surface-variant">
              <div class="box2" @dragover.prevent>
                <div class="w-100 d-flex flex-row justify-center" style="margin-top: 80px">
                  <img src="../../../public/svgs/xlsx_icon 1.svg" />
                </div>

                <div class="w-100 d-flex flex-row justify-center mt-4">
                  <div>
                    <div class="font-weight-light text-center">
                      Drag and drop a file or
                      <div class="uplaodbtn2">
                        browse file
                        <input type="file" accept=".csv" @change="handleFileUpload" name="csv" />
                      </div>
                      <p class="grey--text">(Supported only .csv file)</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-if="image">
                    <div class="imgback">
                      <img :src="image" alt="" width="100" height="auto" class="img" />
                      <div class="pencil">
                        <v-icon class="editpen">mdi-pencil-circle</v-icon>
                        <input class="editlogo" type="file" name="csv" />
                      </div>
                    </div>
                  </div> -->
            </div>

            <v-card-actions class="px-9 pb-6">
              <v-btn @click="downloadTemplate" text class="mx-2 downloadColor">
                <img class="mr-2" src="../../../public/svgs/fi_share.svg" />
                <span class="downloadColor"> Download Template Excel </span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn rounded width="102px" height="48px" outlined class="accent--text pa-4" @click="() => {
          uploadTopicsUsers = false; //
          formbtnBool = false;
        }
          ">Cancel</v-btn>
              <v-btn depressed rounded width="102px" height="48px" class="accent pa-4">Create</v-btn>
            </v-card-actions>
            <!---------------------- subject upload section ends here --------------------------->
          </v-card>
        </v-form>
        <!---------------------- subject upload section starts here --------------------------->
      </v-dialog>

      <v-dialog max-width="600px" max-height="80vh" v-model="uploadingTopicsDialog" center>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6" md="6">
              <v-card-title class="pa-0 ma-0">
                Uploading... {{ bulkTopicUploadResponse.length }} /
                {{ bulkTopicsJsonData.length }}
              </v-card-title>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn class="float-right" icon
                @click="uploadingTopicsDialog = false; getTopics();"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-data-table :headers="headersresponce" :items="bulkTopicUploadResponse" :items-per-page="-1">
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex flex-row ml-2">
                {{ item.status }}

                <v-icon v-if="item.status.includes('Uploaded Successfully!')" color="green">mdi-check-all</v-icon>
                <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row">
                <img width="36px" height="36" @click="updateData(item)" class="cursor" src="../../assets/edit.svg" />
                <img width="36px" height="36" class="cursor" src="../../assets/userdelete.svg" />
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-row class="d-flex justify-center my-0 py-0">
        <v-col>
          <div class="text-h6">Demo Topics</div>
        </v-col>

        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo v-model="search" label="Search here..." prepend-inner-icon="mdi-magnify" hide-details
               class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
            <!-- <v-btn  depressed outlined rounded class="border-button mx-3"
            ><v-icon>mdi-tune</v-icon>FILTER</v-btn
          >  -->
            <v-btn depressed outlined rounded class="border-button mx-3"><v-icon class="pr-2">mdi-tune</v-icon><span
                v-if="filterCount > 0" @click="filterDialog = true">Filter ({{ filterCount }})</span>
              <span v-else @click="filterDialog = true">Filter</span></v-btn>
            <v-btn class="border-button mx-3" :disabled="selected.length == 0" outlined rounded
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2"
            rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn depressed rounded outlined class="border-button mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="$store.state.role.school.admin" class="accent mx-3 white--text" rounded v-bind="attrs"
                  v-on="on" depressed><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>
              </template>
              <v-list>
                <v-list-item @click="(dialog = true), newCreateTopics()">
                  <v-list-item-title>
                    <v-icon class="pr-1">mdi-email-plus-outline</v-icon>CREATE
                    NEW TOPIC
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="uploadTopicsUsers = true">
                  <v-list-item-title>
                    <v-icon>mdi-account-multiple-plus </v-icon>

                    CREATE TOPICS IN BULK
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table"
        v-model="selected" :loading="dataTableLoading" loading-text="Loading Demo Topics.." :headers="headers"
        :search="search"
        :items="topicsData" :options.sync="dataTableOptions" show-select :single-select="singleSelect" :server-items-length="demoTopicsCount" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 100],
        }">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.Brand.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn>
        </template>
        <template v-slot:no-results>
          <div class="mt-16 pt-16">
            <img 
            src="../../assets/noResultFound.svg"/>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-container></v-container>
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-6 pb-0">Delete Topics</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="background black--text" large width="157px" rounded
                  @click="deleteDialog = false">CANCEL</v-btn>
                <v-btn class="accent white--text" depressed large width="157px" :loading="dLoading" rounded
                  @click="deleteData(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="primary" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Topic Deleted</p>
              <v-btn class="accent" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Topic {{ formbtnValue() }}</p>
              <v-btn class="accent" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import BrandController from "@/controllers/BrandController";
import GradeController from "@/controllers/GradeController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";
import TopicsController from "@/controllers/DemoTopicsController";

import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"

import axios from "axios"

export default {
  name: "DemoTopicsView",
  components: {
    Notification, MastersDropdownComponent
  },
  data() {
    return {
      filterCount: 0,
      selectedLevelFilter: [],
      selectedGradeFilter: [],
      selectedSubjectFilter: [],
      filterDialog: false,
      headersresponce: [
        { text: "Index in CSV", value: "topicIndex" },
        { text: "Status", value: "status" },
      ],
      demoTopicsCount : 0,
      bulkTopicUploadResponse: [],
      uploadingTopicsDialog: false,
      content: [],
      bulkTopicsJsonData: [],
      uploadTopicsUsers: false,
      search: "",
      appliedSearch: false,
      searchedResults: [],
      notificationDialog: false,
      options: {},
      dataTableOptions: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      errorMessage: "",
      dialog: false,
      topicName: "",
      focusedIndex: null,
      levelName: "",
      gradeName: "",
      topicsData: [],
      subjectName: "",
      chapterName: "",
      unitName: "",
      subTopics: [],
      subjectsData: [],
      levelsData: [],
      gradesData: [],
      editId: null,
      singleSelect: false,
      deleteDialog: false,
      dLoading: false,
      formbtnBool: false,
      brandData: [],
      selected: [],
      search: "",
      dataTableLoading: true,
      loading: false,
      searchBool: false,
      headers: [
        { text: "Topic Name", value: "topic" },
        { text: "Sub-Topics Name", value: "subTopics" },
        { text: "Subject", value: "subject" },
        { text: "Level", value: "level" },
        { text: "Grade", value: "grade" },
        { text: "Action", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
      gradesDataByLevel: [],
      subjectsDataByGrades: [],
    };
  },
  watch: {
    dataTableOptions: {
      handler() {
        console.log("dataTableOptions entered", this.dataTableOptions);
        this.pageSize = this.dataTableOptions.itemsPerPage;
        this.page = this.dataTableOptions.page;

        this.getTopics();
      },
      deep: true,
    }
  },
  methods: {

    async downloadTemplate(){
      try {
        const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/vgos-sss-stag.appspot.com/o/csv_templates%2FTopics%20Bulk%20Upload%20-%20Sheet1.csv?alt=media&token=59bf0dcc-d16b-4f7b-81aa-e30fb37bf125';
        const response = await axios({
          url: fileUrl,
          method: 'GET',
          responseType: 'blob' // Ensure response is treated as a Blob
        });

        // Create a temporary URL for the Blob
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', 'downloaded_file.csv'); // Set desired filename

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event on the link to initiate download
        link.click();

        // Clean up: remove the link and revoke the Blob URL
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },

    checkSearch(searchItem) {
      console.log("search", searchItem)
    },
    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedGradeFilter.length > 0) {
        this.filterCount += this.selectedGradeFilter.length
      }

      if (this.selectedLevelFilter.length > 0) {
        this.filterCount += this.selectedLevelFilter.length
      }

      if (this.selectedSubjectFilter.length > 0) {
        this.filterCount += this.selectedSubjectFilter.length
      }
    },

    clearFilter() {
      this.selectedLevelFilter = [];
      this.selectedGradeFilter = [];
      this.selectedSubjectFilter = [];
      this.getTopics();
      this.filterDialog = false;
      this.filterCount = 0;
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.csvFileToJSON(file);
      }
    },

    csvFileToJSON(file) {
      var contents = [];

      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results;

            contents = this.content.data;

            var jsonData = [];

            var blankcell = false;
            console.log("parsed data", contents)
            for (var i = 0; i < contents.length; i++) {
              // var cells = rows[i].split(",");

              var rowData = {};
              var cells = contents[i];
              for (var j = 0; j < cells.length; j++) {
                if (i == 0) {
                  var headers = contents[i];
                } else {

                  if (headers[j].includes("subTopics")) {
                    rowData[headers[j]] = cells[j].trim().split(",").map(item => item.trim());
                    continue;
                  }
                  else {
                    rowData[headers[j]] = cells[j].trim();
                  }
                }
              }
              if (i != 0) {
                jsonData.push(rowData);
              }

            }

            this.bulkTopicsJsonData = jsonData;
            console.log("bulk data", this.bulkTopicsJsonData)
            this.uploadTopics();
          }.bind(this),
        });
      } catch (e) {
        console.error(e);
      }
    },

    uploadTopics() {
      this.uploadingTopicsDialog = true;
      this.uploadTopicsUsers = false;

      var index = 1;
      this.bulkTopicsJsonData.forEach(async (topic_) => {
        try {
          const response = await TopicsController.createTopic(topic_);
          if (response.status == 200) {
            console.log(topic_);
            this.bulkTopicUploadResponse.push({
              topicIndex: index,
              status: "Uploaded Successfully!",
            });
          } else {
            this.bulkTopicUploadResponse.push({
              topicIndex: index,
              status: "Uploaded Failed!",
            });
          }
          index++;
        } catch (error) {
          console.log(error);
        }
        console.log(this.bulkTopicUploadResponse);
      });
    },

    async searchTopics(search) {
      const response = await TopicsController.searchTopics(
        this.pageSize,
        this.page,
        search
      );

      console.log(response.data);
      console.log(this.searchBool);
      this.count = response.data.data.count;
      this.topicsData = response.data.data.rows;
    },

    async getTopics() {
      // const response = await TopicsController.getTopics();
      // if (response.status == 200) {
      //   this.topicsData = response.data.topics;
      //   this.dataTableLoading = false;
      // } else {
      //   console.log("subject Not Found!!");
      //   //alert("subject Not Found!!");
      // }
      this.demoTopicsCount = 0;
      const response = await TopicsController.getNewTopics(this.page, this.pageSize, this.search, this.selectedSubjectFilter, this.selectedLevelFilter, this.selectedGradeFilter);
      if (response.status == 200) {
        this.topicsData = response.data.data.topics;
        console.log("topics", this.topicsData)
        this.dataTableLoading = false;
        this.filterDialog = false;
        this.demoTopicsCount = response.data.data.totalTopics;

      } else {
        console.log("subject Not Found!!");
        //alert("subject Not Found!!");
      }
    },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      if (response.status == 200) {
        this.subjectsData = response.data.subjects;
      } else {
        console.log('Subjects NOt Found')
        //alert("subject Not Found!!");
      }
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        this.levelsData = response.data.levels;
      } else {
        console.log("Levels Not Found!!");
        //alert("Levels Not Found!!");
      }
    },
    async getGrades() {
      const response = await GradeController.getAllGrades();
      //console.log(response);

      // console.log("grade data", response);
      if (response.status == 200) {
        this.gradesData = response.data.grades;
      } else {
        alert("Levels Not Found!!");
      }
    },

    addSubTopic(index) {
      // Your logic to add a subtopic
      var val = index + 1;
      this.subTopics.push("");
      setTimeout(() => {
        const inputElement = document.getElementById("subTopicInput" + val);
        inputElement.focus();
      }, 10);

      // Focus on the newly added input
      // this.$nextTick(() => {
      //   console.log(this.$refs['subTopicInputRef_'+index+1])
      //   // this.$refs['subTopicInputRef_'+index+1].focus();
      // });
    },
    deleteSubTopic(index) {
      // Your logic to delete a subtopic
      this.subTopics.splice(index, 1);
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    updateData(item) {
      this.editId = item.id;
      this.dialog = true;
      this.formbtnBool = true; // selected id for edit
      this.unitName = item.unit;
      this.chapterName = item.chapter;
      this.topicName = item.topic;
      this.subTopics = item.subTopics;
      this.subjectName = item.subject;
      this.levelName = item.level;
      this.gradeName = item.grade;
      console.log("sub-topics", item.subTopics);
    },

    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },

    async saveInputs() {
      if (this.$refs.form.validate()) {
        this.formbtnBool == false;
        var res;
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await TopicsController.createTopic({
            unit: this.unitName,
            chapter: this.chapterName,
            topic: this.topicName,
            subTopics: this.subTopics,
            subject: this.subjectName,
            level: this.levelName,
            grade: this.gradeName,
          });
          res = response;
          console.log("create cate", response);
        } else {
          const response = await TopicsController.updateTopic(
            {
              unit: this.unitName,
              chapter: this.chapterName,
              topic: this.topicName,
              subTopics: this.subTopics,
              subject: this.subjectName,
              level: this.levelName,
              grade: this.gradeName,
            },
            this.editId
          );
          console.log(response);
          res = response;
          this.getTopics();
        }
        // Close the dialog

        this.dialog = false;
        if (res.data.flag) {
          this.loading = false;
          this.successDialog = true;
        } else {
          this.loading = false;
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getTopics();
      }
    },

    async deleteData(data) {
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id);
      });
      this.dLoading = true;
      this.deleteDialog = true;
      const response = await TopicsController.deleteBulkTopics(ids);
      if (response.data.flag) {
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
      } else {
        alert(response.data.error);
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
      }
      this.getTopics();
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    async newCreateTopics() {
      (this.topicName = ""),
        (this.levelName = ""),
        (this.gradeName = ""),
        (this.subjectName = ""),
        (this.chapterName = ""),
        (this.unitName = ""),
        (this.subTopics = []),
        (this.formbtnBool = false);
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    downlaodRoles() {
      const fileName = "topics.csv";
      this.downloadCSV(this.topicsData, fileName);
    },
    setGradesByLevel() {
      const gradeNames = this.levelsData?.find(levelData => levelData.name === this.levelName).grades ?? []
      this.gradesDataByLevel = this.gradesData.filter(gradeData => gradeNames.includes(gradeData.name))
    },
    setSubjectsByGrade() {
      const subjectNames = this.gradesData?.find(gradeData => gradeData.name === this.gradeName).subjects ?? []
      this.subjectsDataByGrades = this.subjectsData.filter(subjectData => subjectNames.includes(subjectData.name))
    },
  },
  created() {
    if (!this.$store.state.role.Brand.read) {
      this.$router.push("/notFound");
    }
    this.getTopics();
    this.getSubjects();
    this.getLevels();
    this.getGrades();
    this.$store.state.breadcrumb = "Demo Topics";
  },
};
</script>
<style></style>